import axios from "axios";
import {GoogleReCaptcha, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import { useState } from "react";
import Swal from "sweetalert2";

export default function Hosting(){
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    const baseUrl = 'http://localhost:5000';

    const handlePayment = async (amount, desc) => {
        try {
            const response = await axios.post(`${baseUrl}/verify-recaptcha`, {token: token})
            if(response.data.success){
                amount += (amount * 18) / 100
                const options = {
                    key: 'rzp_test_s5N7BrP1kdVQj6', // Enter the Key ID generated from the Dashboard
                    amount: amount * 100, // Amount is in currency subunits. Default currency is INR.
                    currency: 'INR',
                    name: 'Div Tech',
                    description: desc,
                    image: '/icon-bg.png',
                    handler: async function (resp) {
                        await axios.post(`${baseUrl}/new-server-mail`, {amount, desc, id : resp.razorpay_payment_id});
                        Swal.fire({
                            title: 'Sucess',
                            icon: 'success',
                            text: 'We have received your request, our team for contact you for attaching your domain with the server, and will be providing you the server within 24 hours.'
                        })
                    },
                };
            
                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            }
            else
            {

            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err
            })
        }
    };

    const handleRecaptchaVerify = async () => {
        const recaptchaToken = await executeRecaptcha("home"); // You can specify the action name
        setToken(recaptchaToken);
    };

    return(
        <div className="order">
            <GoogleReCaptcha
                onVerify={handleRecaptchaVerify}
            />
            <div className="container">
                <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="titlepage text_align_center">
                        <h2>Check Out Awesome Plans, <br/> <span className="blue_light">Order Now</span></h2>
                        <p>
                            Explore our exclusive plans crafted for your needs. Enjoy top-notch services and special offers. Join our satisfied customers and experience unmatched quality. Order now and get the best value.
                        </p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    <div id="ho_co" className="order-box_main" onClick={()=>{handlePayment(1100, 'Basic Plan')}}>
                        <div className="order-box text_align_center">
                            <h3>Basic Plan</h3>
                            <p>Starts  <span>₹1100</span>Per Year</p>
                            <a href="Javascript:void(0)">Personal use</a>
                            <ul className="supp">
                            <li>Unlimited projects</li>
                            <li>24/7 support</li>
                            <li>1 GB Storage</li>
                            <li>USA Based Server</li>
                            <li>3 Databases</li>
                            <li>Unlimited Domains and Sub Domains</li>
                            <li>5 Email Accounts</li>
                            <li>SSL Certified for Domains and Sub Domains</li>
                            </ul>
                        </div>
                        <a className="read_more" href="Javascript:void(0)">Buy Now</a>
                    </div>
                </div>
                <div className="col-md-4">
                    <div id="ho_co" className="order-box_main" onClick={()=>{handlePayment(1600, 'Advance Plan')}}>
                        <div className="order-box text_align_center">
                            <h3>Advance Plan</h3>
                            <p>Starts  <span>₹1600</span> Per Year</p>
                            <a href="Javascript:void(0)">Group use</a>
                            <ul className="supp">
                            <li>Unlimited projects</li>
                            <li>24/7 support</li>
                            <li>2 GB Storage</li>
                            <li>USA Based Server</li>
                            <li>5 Databases</li>
                            <li>Unlimited Domains and Sub Domains</li>
                            <li>5 Email Accounts</li>
                            <li>SSL Certified for Domains and Sub Domains</li>
                            </ul>
                        </div>
                        <a className="read_more" href="Javascript:void(0)">Buy Now</a>
                    </div>
                </div>
                <div className="col-md-4">
                    <div id="ho_co" className="order-box_main" onClick={()=>{handlePayment(2100, 'Pro Plan')}}>
                        <div className="order-box text_align_center">
                            <h3>Pro Plan</h3>
                            <p>Starts  <span>₹2100</span> Per Year</p>
                            <a href="Javascript:void(0)">Professional use</a>
                            <ul className="supp">
                            <li>Unlimited projects</li>
                            <li>24/7 support</li>
                            <li>5 GB Storage</li>
                            <li>USA Based Server</li>
                            <li>10 Databases</li>
                            <li>Unlimited Domains and Sub Domains</li>
                            <li>10 Email Accounts</li>
                            <li>SSL Certified for Domains and Sub Domains</li>
                            </ul>
                        </div>
                        <a className="read_more" href="Javascript:void(0)">Buy Now</a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}