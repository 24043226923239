import { useState } from "react";
import {GoogleReCaptcha, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import axios from "axios";
import Swal from "sweetalert2";

export default function Contact(){
    const [formData, setFormData] = useState({name:"", email:"", phone:"", message:""});
    
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    const baseUrl = 'https://backend.divtech.in';
    
    const updateFormData = (e, inputName) => {
        if(inputName === "name"){
            setFormData({...formData, name:e.target.value})
        }
        if(inputName === "email"){
            setFormData({...formData, email:e.target.value})
        }
        if(inputName === "phone"){
            setFormData({...formData, phone:e.target.value})
        }
        if(inputName === "message"){
            setFormData({...formData, message:e.target.value})
        }
    }
    const formSubmit = async (e)=>{
        e.preventDefault();
        try {
            const response = await axios.post(`${baseUrl}/verify-recaptcha`, {token: token})
            if(response.data.success){
               axios.post(`${baseUrl}/send-mail`, {name: formData.name, email: formData.email, phone: formData.phone, message: formData.message});
            }
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your enquiry has been received by us. We will contact you within 48 hours.'
            });
            setFormData({name:"", email:"", phone:"", message:""});
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err
            })
        }
    }
    const handleRecaptchaVerify = async () => {
        const recaptchaToken = await executeRecaptcha("home"); // You can specify the action name
        setToken(recaptchaToken);
    };

    return(
        <div className="contact">
            <div className="container">
                <div className="row ">
                <div className="col-md-12">
                    <div className="titlepage text_align_center">
                        <h2>Contact <span className="blue_light">Us</span></h2>
                    </div>
                </div>
                <div className="col-md-10 offset-md-1">
                    <form onSubmit={(e)=>{formSubmit(e)}} id="request" className="main_form">
                        <div className="row">
                            <div className="col-md-12 ">
                                <input required='true' onChange={(e)=>{updateFormData(e, "name")}} value={formData.name} className="contactus" placeholder="Name" type="text" name=" Name"/> 
                            </div>
                            <div className="col-md-12">
                                <input pattern="^[0-9]{10}$" required='true' onChange={(e)=>{updateFormData(e, "phone")}} value={formData.phone} className="contactus" placeholder="Phone number" type="tel" name="Phone Number"/>                          
                            </div>
                            <div className="col-md-12">
                                <input required='true' onChange={(e)=>{updateFormData(e, "email")}} value={formData.email} className="contactus" placeholder="Your Email" type="email" name="Email"/>                          
                            </div>
                            <div className="col-md-12">
                                <textarea required='true' onChange={(e)=>{updateFormData(e, "message")}} value={formData.message} className="textarea" placeholder="Message"></textarea>
                            </div>
                            <GoogleReCaptcha
                                onVerify={handleRecaptchaVerify}
                            />
                            <div className="col-md-12">
                                <button className="send_btn">Submit Now</button>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}