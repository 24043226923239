import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Index from "./Pages/Index";
import AboutPage from "./Pages/AboutPage";
import HostingPage from "./Pages/HostingPage";
import ContactPage from "./Pages/ContactPage";
import Marketing from "./Pages/Marketing";

const Loader = () => (
  <div className="loader_bg">
    <div className="loader"><img src="images/loading.gif" alt="#"/></div>
  </div>
);

const App = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    handleStart();
    const timeout = setTimeout(handleComplete, 1000); // Simulate loading time

    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/hosting" element={<HostingPage />} />
        <Route path="/marketing" element={<Marketing />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;