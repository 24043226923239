import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";

export default function ContactPage(){
    return(
        <div className="inner_page">
            {/* Navbar */}
            <Navbar page="contact" />
            {/* Contact */}
            <Contact />
            {/* Footer */}
            <Footer />
        </div>
    )
}