import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";

export default function Marketing(){
    return(
        <div>
            {/* Navbar */}
            <Navbar page="marketing" />

            {/* Carousel */}
            <div className="full_bg">
                <div className="slider_main">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div id="banner1" className="carousel slide">
                                <ol className="carousel-indicators">
                                <li data-target="#banner1" data-slide-to="0" className="active"></li>
                                <li data-target="#banner1" data-slide-to="1"></li>
                                <li data-target="#banner1" data-slide-to="2"></li>
                                </ol>
                                <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="carousel-caption relative">
                                        <div className="row">
                                            <div className="col-md-6">
                                            <div className="dream">
                                                <h1>
                                                    Crafting <br/>Digital <br/>Excellence <br/>Solutions
                                                </h1>
                                                <a className="read_more" href="Javascript:void(0)">Get Stared</a>    
                                                <a className="read_more" href="Javascript:void(0)">Contact Us</a>
                                            </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className="dream_img">
                                                <figure><img src="images/marketing-1.png" alt="#"/></figure>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="carousel-caption relative">
                                        <div className="row">
                                            <div className="col-md-6">
                                            <div className="dream">
                                                <h1>
                                                    Tailored <br/>Marketing <br/>Solutions
                                                </h1>
                                                <a className="read_more" href="Javascript:void(0)">Get Stared</a>    
                                                <a className="read_more" href="Javascript:void(0)">Contact Us</a>
                                            </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className="dream_img">
                                                <figure><img src="images/marketing-2.png" alt="#"/></figure>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="carousel-caption relative">
                                        <div className="row">
                                            <div className="col-md-6">
                                            <div className="dream">
                                                <h1>
                                                    Develop <br/>Market <br/>Lead Gen <br/>Earn
                                                </h1>
                                                <a className="read_more" href="Javascript:void(0)">Get Stared</a>    
                                                <a className="read_more" href="Javascript:void(0)">Contact Us</a>
                                            </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className="dream_img">
                                                <figure><img src="images/carousel-3.png" alt="#"/></figure>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <a className="carousel-control-prev" href="#banner1" role="button" data-slide="prev">
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#banner1" role="button" data-slide="next">
                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            {/* About */}
            <div className="about">
                <div className="container">
                    <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="about_img text_align_center">
                            <div class="row">
                                <div class="col-md-4">
                                    <h1 style={{fontSize:'50px'}}>3 - 6x</h1>
                                    <p>Avg ROAS achieved</p>
                                </div>
                                <div class="col-md-4">
                                    <h1 style={{fontSize:'50px'}}>$18,000+</h1>
                                    <p>Monthly Ad-spend</p>
                                </div>
                                <div class="col-md-4">
                                    <h1 style={{fontSize:'50px'}}>2 years</h1>
                                    <p>industry experience</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div style={{marginBottom:'0px', marginTop:'20px'}} className="titlepage text_align_center">
                                    <h2>What We <span className="blue_light">Offer</span></h2>
                                </div>
                            </div>
                                <p style={{paddingBottom: '10px'}}>
                                    Dedicated to providing top-notch marketing services & consultation, We work closely with clients to align strategies with their goals. With our strategical and statistical support, clients can rely on insightful data-driven decisions, innovative campaign strategies, and a commitment to delivering measurable results that propel their business growth to new heights.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}