import Navbar from "./Components/Navbar";
import About from "./Components/About";
import Footer from "./Components/Footer";
export default function AboutPage(){
    return(
        <div className="inner_page">
            {/* Navbar */}
            <Navbar page="about" />
            {/* About */}
            <About />
            {/* Footer */}
            <Footer />
        </div>
    );
}