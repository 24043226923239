import { useRef } from "react";
export default function About(props){
    const p1 = useRef();
    const p2 = useRef();
    const readMoreBtn = useRef();
    const showMore = () => {
        if(p1.current.style.display === 'none'){
            p1.current.style.display = 'block';
            p1.current.style.opacity = '1';
            p2.current.style.display = 'block';
            p2.current.style.opacity = '1';

            readMoreBtn.current.text = 'Read Less'
        }
        else{
            p1.current.style.display = 'none';
            p1.current.style.opacity = '0';
            p2.current.style.display = 'none';
            p2.current.style.opacity = '0';

            readMoreBtn.current.text = 'Read More'
        }
    }
    return(
        <div className="about">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="titlepage text_align_center">
                        <h2>About <span className="blue_light">Company</span></h2>
                    </div>
                </div>
                <div className="col-md-10 offset-md-1">
                    <div className="about_img text_align_center">
                        <figure><img src="images/about.png" alt="#"/></figure>
                        <p style={{paddingBottom: '10px'}}>
                            Div Tech was conceived in 2022 and officially launched in 2023 with a vision to transform the digital landscape. We specialize in delivering a comprehensive range of services including web hosting, mobile and web development, standalone PC development, digital marketing, and annual maintenance contracts (AMC). Our commitment to excellence and innovation has enabled us to complete nearly 10 successful projects within a short span of time.
                        </p>
                        <p ref={p1} style={{display:'none', opacity:0, paddingBottom: '10px', paddingTop: '10px'}}>
                            At Div Tech, we believe in the power of technology to drive business success. Our dedicated team of professionals is passionate about creating cutting-edge solutions that meet the unique needs of our clients. From start-ups to established enterprises, we partner with businesses to help them achieve their goals through our tailored services.
                        </p>
                        <p ref={p2} style={{display:'none', opacity:0, paddingBottom: '10px', paddingTop: '10px'}}>
                            Our journey so far has been marked by continuous growth and a strong focus on customer satisfaction. As we move forward, we remain committed to delivering high-quality solutions that empower our clients and foster lasting partnerships.
                        </p>
                        <a className="read_more" onClick={()=>{showMore()}} ref={readMoreBtn} href="Javascript:void(0)">Read More</a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}