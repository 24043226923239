import { Link } from "react-router-dom";

export default function Navbar(props){
   const page = props.page;
    return(
        <div className="header">
         <div className="container">
            <div className="row d_flex">
               <div className=" col-md-2 col-sm-3 col logo_section">
                  <div className="full">
                     <div className="center-desk">
                        <div className="logo">
                           {/* <a href="/"><img src="images/logo.png" alt="#" /></a> */}
                           <Link to='/'>
                              <img src="icon-bg.png" alt="#" />
                              <h3>Div Tech</h3>
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-10 col-sm-12">
                  <nav className="navigation navbar navbar-expand-md navbar-dark ">
                     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                     <span className="navbar-toggler-icon"></span>
                     </button>
                     <div className="collapse navbar-collapse" id="navbarsExample04">
                        <ul className="navbar-nav mr-auto">
                           <li className={(page === "index")?"nav-item active":"nav-item"}>
                              <Link to='/' className="nav-link">Home</Link>
                           </li>
                           <li className={(page === "marketing")?"nav-item active":"nav-item"}>
                              <Link to='/marketing' className="nav-link">Marketing</Link>
                           </li>
                           <li className={(page === "hosting")?"nav-item active":"nav-item"}>
                              <Link to='/hosting' className="nav-link">Hosting</Link>
                           </li>
                           <li className={(page === "about")?"nav-item active":"nav-item"}>
                              <Link to='/about' className="nav-link">About</Link>
                           </li>
                           <li className={(page === "contact")?"nav-item active":"nav-item"}>
                              <Link to='/contact' className="nav-link">Contact Us</Link>
                           </li>
                        </ul>
                     </div>
                  </nav>
               </div>
            </div>
         </div>
      </div>
    )
}