import Footer from "./Components/Footer";
import Hosting from "./Components/Hosting";
import Navbar from "./Components/Navbar";

export default function HostingPage(){
    return(
        <div className="inner_page">
            {/* Navbar */}
            <Navbar page="hosting" />
            {/* Hosting */}
            <Hosting />
            {/* Footer */}
            <Footer />
        </div>
    )
}