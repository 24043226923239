import { Link } from "react-router-dom";
export default function Footer(){
    const date = new Date();
    return(
        <footer>
         <div className="footer">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <div className="infoma text_align_left">
                        <h3>Choose.</h3>
                        <ul className="commodo">
                           <li>Commodo</li>
                           <li>consequat. Duis a</li>
                           <li>ute irure dolor</li>
                           <li>in reprehenderit </li>
                           <li>in voluptate </li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                     <div className="infoma">
                        <h3>Get Support.</h3>
                        <ul className="conta">
                           <li><i className="fa fa-map-marker" aria-hidden="true"></i>Address : Tadwadi, Surat 
                           </li>
                           <li><i className="fa fa-phone" aria-hidden="true"></i><a href="tel:9924721067">Call : +91 9924721067</a></li>
                           <li> <i className="fa fa-envelope" aria-hidden="true"></i><a href="mailto:divtech6@gmail.com"> Email : divtech6@gmail.com</a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <div className="infoma">
                        <h3>Company.</h3>
                        <ul className="menu_footer">
                           <li><Link to='/'>Home</Link></li>
                           <li><Link to='/marketing'>Marketing</Link></li>
                           <li><Link to='/hosting'>Hosting</Link></li>
                           <li><Link to='/about'>About</Link></li>
                           <li><Link to='/contact'>Contact</Link></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6">
                     <div className="infoma text_align_left">
                        <h3>Services.</h3>
                        <ul className="commodo">
                           <li>Commodo</li>
                           <li>consequat. Duis a</li>
                           <li>ute irure dolor</li>
                           <li>in reprehenderit </li>
                           <li>in voluptate </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <div className="copyright">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <p>© {date.getFullYear()} All Rights Reserved. <a href="https://div-tech.in/"> Div Tech</a></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
    );
}